<template>
  <div class="summary-container">
    <div class="summary-header">
      <v-icon>mdi-creation</v-icon>
      <span>AI Summary</span>
    </div>
    <div
      ref="summaryRef"
      class="summary-content"
      :class="{ expanded: expanded || showFullText }"
    >
      <p>{{ summary }}</p>
      <div
        class="gradient-overlay"
        v-if="shouldShowToggle && !expanded && !showFullText"
      ></div>
    </div>
    <button
      v-if="shouldShowToggle && !showFullText"
      v-on:click.stop="toggleExpand()"
      class="toggle-btn"
    >
      {{ expanded ? "Show Less" : "Show More" }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ai-summary",
  props: {
    summary: {
      type: String,
      default: "",
    },
    showFullText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shouldShowToggle: false,
      expanded: false,
    };
  },
  mounted() {
    this.updateToggle();
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    updateToggle() {
      this.$nextTick(() => {
        if (this.$refs.summaryRef) {
          this.shouldShowToggle = this.$refs.summaryRef.scrollHeight > 58;
        }
      });
    },
  },
  watch: {
    summary() {
      this.updateToggle();
    },
  },
};
</script>

<style scoped>
.summary-container {
  width: 100%;
  padding: 10px;
  background-color: #192b4d;
  color: white;
}

.summary-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.summary-content {
  width: 100%;
  font-style: italic;
  position: relative;
  line-height: 1.4;
  max-height: 3.6em;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.summary-content p {
  margin-bottom: 0;
}

.expanded {
  max-height: fit-content;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.2em;
  background: linear-gradient(transparent, #192b4d);
}

.toggle-btn {
  width: 100%;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 5px;
}
</style>
